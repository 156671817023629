<template>

    <b-modal class="modal" id="delete-account-modal" hide-footer centered>

      <!-- Alert Messages -->
      <Alerts :_error="error" :_success="success"></Alerts>

        <!-- Modal title -->
        <template #modal-title>Confirm Deletion</template>
      <p>If you would like to permanently delete your Sabasi account, click <strong>"Delete My Account &amp; Data"</strong>. This will erase all your surveys, personal data and make your account permanently unusable.</p>
      <div class="mb-4">
            <input v-model="password" class="form-control" type="password" :disabled="wait" />
        </div>        
        <button class="custom-btn custom-btn-secondary me-2" @click="$bvModal.hide('delete-account-modal')">Cancel</button>
      <button class="custom-btn custom-btn-danger" @click="deleteAccount()" :disabled="wait">
        <template v-if="wait">Please wait...</template>
        <template>Delete My Account &amp; Data</template>
      </button>
        <div>
        </div>

    </b-modal><!-- End modal -->

</template>

<script>
import Alerts from "../Helpers/Alerts";

export default {
  name: "DeleteAccount",
  props: ['user_id'],
  components: {Alerts},

  data() {
    return {
      password: ''
    }
  },
  methods: {
    deleteAccount() {
      this.wait = true
      this.clear()
      this.$http.post(`api/profile/account_delete`, {password: this.password}).then(() => {
        let msg = "Account deleted successfully!"
        this.success = msg
        this.$toaster.success(msg)
        setTimeout(() => this.$router.push(`/`), 500)
      }).catch(error => {
        this.showError(error)
      }).then(() => {
        this.wait = false
      })
    },
  }
}
</script>

<style scoped>
</style>