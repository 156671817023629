<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div class="row g-3">
            <div class="col-md-4">

                <!-- Profile image -->
                <div class="profile-img-card mb-3">
                    <div class="profile-img-card-header"></div>
                    <div class="profile-img">
                        <b-avatar :text="getFirstLetters(profile.name)" size="5rem"></b-avatar>
                        <span class="user-name">{{ profile.name }}</span>
                        <span class="user-email">{{ profile.email }}</span>
                        <span class="mt-2">
                            <a class="text-danger" href="#" @click.prevent="logout()">Sign out of your account</a>
                        </span>
                    </div>
                </div>

                <!-- Profile details -->
                <div class="profile-details-card mb-3">
                    <h6>Your interests</h6>
                    <ul v-if="profile.categories.length !== 0" class="user-interests">
                        <li v-for="c in profile.categories" :key="c.id">
                            <a href="#">{{ c.name }}</a>
                        </li>
                    </ul>
                    <p v-else class="text-muted mb-0">Please select one or more categories.</p>
                </div>

            </div>

            <div class="col-md-8">

                <h6 class="mb-3">Personal Information</h6>

                <!-- Edit Profile -->
                <div class="profile-info-card mb-3">
                    <form @submit.prevent="saveProfile()" class="mb-3">

                        <Alerts :_success="success" :_error="error"></Alerts>

                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">Name</label>
                                <input v-model="profile.name" type="text" class="form-control" :disabled="wait">
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">Username</label>
                                <input v-model="profile.username" type="text" class="form-control" :disabled="wait">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <!--                            <div class="col-md-4">-->
                            <!--                                <label class="form-label">Country</label>-->
                            <!--                                <select class="form-control" v-model="profile.country">-->
                            <!--                                    <option value="">&#45;&#45;Choose Country&#45;&#45;</option>-->
                            <!--                                    <option v-for="c in countries" :key="c.name" value="c.name">{{ c.name }}</option>-->
                            <!--                                </select>-->
                            <!--                            </div>-->
<!--                          <div class="col-md-3">-->
<!--                            <label class="form-label">States/Counties</label>-->
<!--                            <select class="form-control" v-model="profile.store">-->
<!--                              <option value="">&#45;&#45;Choose State&#45;&#45;</option>-->
<!--                              <option v-for="s in states" :key="s.id" value="s.id">{{ s.name }}</option>-->
<!--                            </select>-->
<!--                          </div>-->
                          <div class="col-md-4">
                            <label class="form-label">Country Code</label>
                            <input v-model="profile.country_code" type="text" placeholder="Enter your Country Code"
                                   class="form-control" :disabled="wait"/>
                          </div>
                            <div class="col-md-4">
                                <label class="form-label">Mobile</label>
                                <input v-model="profile.phone" type="text" placeholder="Enter your phone number"
                                       class="form-control" :disabled="wait"/>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-4">
                                <label class="form-label">Date of Birth</label>
                                <Datepicker id="expected_date" :bootstrap-styling="true" :disabledDates="disabledDates"
                                            v-model="profile.dob" maximum-view="year" :disabled="wait"
                                            placeholder="Select date" :required="true"></Datepicker>

                                <!--                                <b-form-datepicker id="start_date" v-model="profile.dob"-->
                                <!--                                                   :disabled="wait"-->
                                <!--                                                   :date-disabled-fn="dateDisabled"></b-form-datepicker>-->
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">Preferred Language</label>
                                <select class="form-select" v-model="profile.lang">
                                    <option value="en">English</option>
                                    <option value="fr">French</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">Location</label>
                                <input v-model="profile.location" type="text" placeholder="Enter your physical location"
                                       class="form-control" :disabled="wait">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label class="form-label">Your Interests</label>
                                <multiselect
                                    class="mb-2"
                                    v-model="selected_categories"
                                    :options="categories"
                                    :disabled="wait"
                                    :multiple="true"
                                    track-by="id"
                                    :preserve-search="true"
                                    :hide-selected="true"
                                    placeholder="Select categories" label="name" @input="setCategoryIds">
                                </multiselect>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button type="submit" class="custom-btn custom-btn-primary" :disabled="wait">
                                <span v-if="profile.id">Update Profile</span>
                                <span v-else>Save Profile</span>
                            </button>
                        </div>
                    </form>


                </div><!-- End profile-info-card -->

                <!-- Change password -->
                <h6 class="mb-3">Change Password</h6>
                <div class="profile-info-card mb-3">
                    <form @submit.prevent="changePassword()">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Current Password</label>
                                <input 
                                    v-model="auth.current" 
                                    required
                                    :type="auth.show_password ? 'text' : 'password'"
                                    class="form-control"
                                    :disabled="wait"
                                >
                            </div>

                            <div class="col-md-4 mb-3">
                                <label class="form-label">New Password</label>
                                <input 
                                    v-model="auth.password" 
                                    :type="auth.show_password ? 'text' : 'password'"
                                    required 
                                    class="form-control"
                                    :disabled="wait"
                                >
                            </div>

                            <div class="col-md-4 mb-3">
                                <label class="form-label">Confirm Password</label>
                                <input
                                    v-model="auth.password_confirmation"
                                    :type="auth.show_password ? 'text' : 'password'"
                                    required
                                    class="form-control"
                                    :disabled="wait"
                                >
                            </div>

                        </div><!-- End row -->

                        <!-- Switch to show/hide password -->
                        <div class="mb-2">
                            <div class="form-check form-switch">
                                <input class="form-check-input" v-model="auth.show_password" type="checkbox">
                                <label class="form-label">Show password?</label>
                            </div>
                        </div>

                        <div class="mb-2">
                            <button class="custom-btn custom-btn-primary">Change Password</button>
                        </div>

                    </form>
                </div><!-- End profile info card -->          

                <!-- Delete account -->
                <h6 class="mb-3">Delete Account</h6>
                <div class="profile-details-card">
                    <p>If you would like to permanently delete your Sabasi account, click <strong>"Delete My Account &amp; Data"</strong>. This will erase all your surveys, personal data and make your account permanently unusable.</p>
                    <button class="custom-btn custom-btn-danger" v-b-modal.delete-account-modal>Delete My Account &amp; Data</button>
                </div>  

            </div><!-- End col-md-8 -->
        </div>
        <div>
            <DeleteAccount />
        </div>
    </div>
</template>

<script>
import Alerts from "../Helpers/Alerts";
import Multiselect from "vue-multiselect";
import Datepicker from 'vuejs-datepicker'
import DeleteAccount from './DeleteAccount.vue'

export default {
    name: "Profile",
    components: {Alerts, Multiselect, Datepicker, DeleteAccount},
    props: ['id'],
    data() {
        return {
            profile: {
                name: "",
                username: "",
                email: "",
                phone: "",
                country_code: "",
                state: "",
                location: "",
                dob: new Date(),
                lang: 'en',
                category_ids: [],
                categories:[],
                isValid: false,
            },
            auth: {
                show_password: false,
                current: '',
                password: '',
                password_confirmation: ''
            },
            categories: [],
            countries: [],
            states: [],
            selected_categories: [],
            disabledDates: {
                from: new Date(new Date().setDate(new Date().getDate() - 360)),
            },

        }
    },
    mounted() {
        this.getProfile()
        this.getCategories()
        // this.getCountries()
        // this.getStates()
    },
    methods: {
        logout() {
            if (confirm('Are you sure you want to log out?')) {
                localStorage.clear();
                window.location.href = '/'
            }
        },
        getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        },
        setCategoryIds(categories) {
            let ids = [];
            categories.forEach((i) => {
                ids.push(i.id);
            });
            this.profile.category_ids = ids;
        },
        getProfile() {
            this.$http.get("/api/profile").then(response => {
                this.profile = response.data.data
                this.selected_categories = this.profile.categories
                if (!this.profile.dob) {
                    this.profile.dob = this.disabledDates.from
                }
            });
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        },
        // getCountries() {
        //     this.$http.get('/api/country').then(res => {
        //         this.countries = res.data.data
        //     })
        // },
        // getStates() {
        //   this.$http.get('/api/states').then(res => {
        //     this.states = res.data.data
        //   })
        // },
        onSelect(event) {
            console.log(event.target.value)
        },
        // dateDisabled(ymd, date) {
        //     // Disable weekends (Sunday = `0`, Saturday = `6`) and
        //     // disable days that fall on the 13th of the month
        //     const weekday = date.getDay()
        //     const day = date.getDate()
        //     // Return `true` if the date should be disabled
        //     return weekday === 0 || weekday === 6 || day === 13
        // },
        changePassword() {
            window.scroll(0, 0)
            this.clear()
            this.wait = true
            this.$http.post("/api/profile/password", this.auth).then(() => {
                let msg = 'Password changed successfully'
                this.$toaster.success(msg)
                this.success = msg
                this.$router.push('/login')

            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            });
        },
        saveProfile() {
            this.clear()
            this.wait = true
            this.profile.dob = this.formatDate(this.profile.dob)
            this.$http.post("/api/profile", this.profile).then(response => {
                let msg = 'Profile updated'
                this.$toaster.success(msg)
                //this.success = msg
                this.profile = response.data.data
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            });
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>